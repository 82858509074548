import { FetchError } from '../../constants/api-routes.const';

export class FetchErrorWithBody extends FetchError {
  constructor(
    message: string,
    response: Response,
    public body: any,
  ) {
    super(message, response);
  }
}

export async function wrapFetch(call: () => Promise<any>) {
  const res = await call();
  if (res.ok) {
    if (res.status === 204) {
      return null;
    }
    if (res.headers.get('content-type')?.includes('application/json')) {
      const body = await res.json();
      return body;
    }
  } else {
    let errorBody = null;
    if (res.headers.get('content-type')?.includes('application/json')) {
      try {
        errorBody = await res.json();
      } catch (e) {
        // If parsing fails, errorBody remains null
      }
    }
    throw new FetchErrorWithBody('Invalid status: ' + res.status, res, errorBody);
  }
}
